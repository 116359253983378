import React from 'react'
import Layout from '../components/layout'
import {navigate} from '@reach/router'
import {
	Container,
	TopBar, HomeFooter
} from '../components/layouts'

import { Card, Flex, } from 'reakit'
import {Button} from '../components/ui'

import theme from '../theme/content'

const content = theme.pagesContent.login


const NotFoundPage = () => (
	<Layout>
		<TopBar />
		<Container bg={content.bg}>
			<Flex
				width={'100%'}
				height={'100vh'}
				alignItems={'center'}
				justifyContent={'center'}
				flexDirection={'column'}
			>
				<Card
					background={'transparent'}
					width="100%"
					maxWidth="500px"
					margin="90px 50px"
					padding="2em"
					alignContent="center"
					alignItems="center"
					boxShadow={'none'}
				>
					<h1>NOT FOUND</h1>
					<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
					{(typeof(window) !== 'undefined' && window.history.length > 0) ?
						<Button onClick={()=>window.history.back()}>Go Back</Button>
						:
						<Button onClick={()=>navigate('/login/')}>Log In</Button>
					}
				</Card>
			</Flex>
		</Container>
		<HomeFooter />
	</Layout>
)

export default NotFoundPage
